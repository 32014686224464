<template>
  <div class="container-fuild row vh-100 justify-content-center align-items-center p-lg-5 p-3 m-0">
    
      <!-- <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 d-xl-flex d-none">
        <div class="text-shadow-example ">
          <div class="d-flex align-items-center">
            <span class="text-center">
              <i class="bi bi-kanban-fill fs-1 icon"></i>
            </span>
            <span class="fs-2 text-center text-primary fw-bold">
              PSM - Gestion Commerciale 
            </span>
          </div>
          <br>
          <div class="w-100 text-center">
            <img src="@/assets/banner.svg" alt="" class="w-50">
          </div>
          

          <div class="text-center">
            © 2024 <a href="www.peascom.ma">Peascom </a>, Tous les droits sont réservés
          </div>
        </div>
      </div> -->

      <div
        class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 login-card shadow-lg p-4 rounded "
      >
        <div class="text-center">
          <span class="text-center">
            <i class="bi bi-kanban-fill fs-1 icon"></i>
          </span>
          <br />
          <span class="fs-3">
            Bienvenue !
          </span>
        </div>
        <br />
        <p class="lh-2 fs-5 text-center">
          PSM - Gestion Commerciale est une solution logicielle complète conçue pour optimiser vos opérations commerciales. 
        </p>
        <br>
        <form >
          <div>
          <label class="form-label" for=""> Nom d'utilisateur </label>
          <input
            v-model="auth.username"
            type="text"
            class="form-control opactity-8"
            :class="usernameError ? 'border text-danger border-danger' : ''"
          />
          <div class="text-danger">
            {{ usernameError }}
          </div>
          </div>

          <br />
          <div>
            <label class="form-label"> Mot de passe </label>
            <div></div>
            <input
              v-model="auth.password"
              v-on:keyup.enter="login(auth)"
              :type="showPassword==true?'text':'password'"

              class="form-control opactity-8"
              :class="passwordError ? 'border text-danger border-danger' : ''"
            />
            <div class="text-danger">
              {{ passwordError }}
            </div>
          </div>

          <br />
          <div>
            <span class="d-flex align-items-center">
              <input type="checkbox" name="" id="password_display" v-model="showPassword" />
              <label for="password_display" class="ms-2 btn btn-link nav-link"
                >Afficher le mot de passe ?</label
              >
            </span>
          </div>  
        </form>

        <br />
        <div class="col">
          <button type="submit" @click="login(auth)" class="btn btn-primary w-100 py-2 px-4">
            <span v-if="!progress"> Connecter </span>
            <span v-else-if="progress"> Vérification en cours </span>
          </button>
          <div class="text-center text-danger p-2">
            {{ error }}
          </div>
        </div>
      </div>
      <div class="text-center">
        © 2024 <a href="www.peascom.ma">Peascom </a>, Tous les droits sont réservés
      </div>
    

    
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      auth: {},
      progress: false,
      showPassword: false
    };
  },
  methods: {
    async login(auth) {
      await (this.progress = !this.progress);
      await this.$store.dispatch("auth/login", auth);
      setTimeout((this.progress = !this.progress), 6000);
    },
  },
  computed: {
    ...mapGetters("auth", {
      usernameError: "getUsernaneError",
      passwordError: "getPasswordError",
      error: "getError",
    }),
  },
  created() {
    try {
      if (this.$route.query.username && this.$route.query.password) {
        const username = this.$route.query.username;
        const password = this.$route.query.password;
        alert({ username, password });

        // Assuming your login method expects an object
        this.login({ username, password }); // Pass username and password as data
      }
    } catch (e) {
      console.log(e.message);
    }
  },
};
</script>

<style scoped>
.container-fuild {
  min-height: 100vh;
  /* background-color: red; */
  /*background-color: #689ae6;*/
   /* background-image: -webkit-linear-gradient(
    -28deg,
    #d9725f 0%,
    #d9725f 60%,
    #6f73fc 60%,
    #6f73fc 60%
  ); */
  /*background-image: linear-gradient(
    -28deg,
    #d9725f 0%,
    #d9725f 60%,
    #6f73fc 60%,
    #6f73fc 60%
  ); */
  background-image: url('../../assets/banner.svg') !important;
  background-size: contain !important;
}

.login-card {
  background: rgba(255, 255, 255, 0.6);

  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}
.opactity-8{
  background: rgba(255, 255, 255, 0.9);
}
@keyframes icon-animation {
  0% {
    color: #689ae6;
  }
  50% {
    color: #b14343;
  }
  100% {
    color: #689ae6;
  }
}
.icon {
  animation-name: icon-animation;
}
.fs-1 {
  font-size: 55px !important;
}
.form-control {
  padding: 0.6rem 0.8rem;
}
 .text-shadow-example , .text-shadow-example span >*  {
      font-size: 5px;
      font-weight: bold;
      text-shadow: 2px 2px 4px rgba(145, 145, 145, 0.5);
    }
</style>
